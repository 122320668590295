import React, { useState } from 'react';
import css from './ListingPage.module.css';
import Up from '../../assets/icons/up.png';
import Down from '../../assets/icons/down.png';

function SectionRates(props) {
  const [open, setOPen] = useState(false);
  const { currentListing } = props;

  const { publicData = [] } = currentListing.attributes;

  const { priceIncludes = '', priceExcludes = '', seasonality = [] } = publicData;

  const highSeason = seasonality.filter(s => s.parent == 'High Season');
  const lowSeason = seasonality.filter(s => s.parent == 'Low Season');

  return (
    <div className={css.mobilePadding}>
      {seasonality.length > 0 || priceIncludes || priceExcludes ? (
        <h2 className={css.ratesHeader}>Rates</h2>
      ) : null}

      {priceIncludes && (
        <div className={css.margin}>
          {priceIncludes && <span className={css.rates}>Indicative day rate includes: </span>}
          <span className={css.priceRate}>{priceIncludes}</span>
          <br></br>
          {priceExcludes && <span className={css.rates}>Indicative day rate excludes: </span>}
          <span className={css.priceRate}>{priceExcludes}</span>
        </div>
      )}

      {seasonality && seasonality.length > 0 ? (
        <>
          <div className={css.seasonalityHeader}>Seasonality</div>

          {highSeason.length > 0 && (
            <div className={css.highSeason}>
              <div
                onClick={() => setOPen(open == 'high' ? '' : 'high')}
                className={css.dropDownandUp}
              >
                <p className={css.highSeasonParent}>High season</p>
                {open && open == 'high' ? <img src={Up} /> : <img src={Down} />}
              </div>

              {highSeason.map(e => {
                const { title, desc } = e;
                if (open && open == 'high') {
                  return (
                    <div className={open ? css.highSeasonDetailsshown : css.highSeasonDetails}>
                      <div>
                        <strong>{title}</strong>:
                      </div>
                      <div>{desc}</div>
                    </div>
                  );
                }
              })}
            </div>
          )}

          {lowSeason.length > 0 && (
            <div className={css.highSeason}>
              <div
                onClick={() => setOPen(open == 'low' ? '' : 'low')}
                className={css.dropDownandUp}
              >
                <p className={css.highSeasonParent}>Low season</p>
                {open && open == 'low' ? <img src={Up} /> : <img src={Down} />}
              </div>

              {lowSeason.map(e => {
                const { title, desc } = e;
                if (open && open == 'low') {
                  return (
                    <div className={open ? css.highSeasonDetailsshown : css.highSeasonDetails}>
                      <div>
                        <strong>{title}</strong>:
                      </div>
                      <div>{desc}</div>
                    </div>
                  );
                }
              })}
            </div>
          )}
        </>
      ) : null}
    </div>
  );
}

export default SectionRates;
