import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import css from './ListingPage.module.css';

function SectionDetailsMaybe(props) {
  const { currentListing } = props;
  const { publicData = [] } = currentListing.attributes;
  const {
    listingType: listingTypeId,
    serviceType = '',
    industryAccreditationURL = '',
    industryAccreditationURLTwo = '',
    maxEventCapacity = '',
    maxSleepCapacity = '',
    noOfUniqueBedrooms = '',
    noOfBathrooms = '',
    retreatProgramme = '',
  } = publicData;

  return (
    <div className={css.mobilePadding}>
      <div>
        {serviceType == 'VE' && (
          <div className={css.margin}>
            <h3 className={css.detailstitle}>
              <FormattedMessage id="ListingPage.detailsTitle" />
            </h3>
            {maxEventCapacity ? (
              <>
                <span className={css.textBolded}>
                  <FormattedMessage id="ListingPage.detailsMaximumSpaceCapacity" />
                </span>
                {maxEventCapacity} People<br></br>
              </>
            ) : null}

            {listingTypeId !== 'experiences' && (
              <>
                {noOfUniqueBedrooms ? (
                  <>
                    <span className={css.textBolded}>
                      <FormattedMessage id="ListingPage.detailsUniqueBedroom" />
                    </span>
                    {noOfUniqueBedrooms} bedrooms<br></br>
                  </>
                ) : null}
                {maxSleepCapacity ? (
                  <>
                    <span className={css.textBolded}>
                      <FormattedMessage id="ListingPage.detailsSleepCapacity" />
                    </span>
                    {maxSleepCapacity} people <br></br>
                  </>
                ) : null}
                {noOfBathrooms ? (
                  <>
                    <span className={css.textBolded}>
                      <FormattedMessage id="ListingPage.detailsBathroomCapacity" />
                    </span>
                    {noOfBathrooms} bathrooms
                  </>
                ) : null}
                {retreatProgramme ? (
                  <>
                    <br></br>
                    <span className={css.textBolded}>Retreat Programme: </span>
                    <br></br>
                    <span className={css.multiLine}>{retreatProgramme}</span>
                  </>
                ) : null}
              </>
            )}
          </div>
        )}

        {industryAccreditationURL || industryAccreditationURLTwo ? (
          <div className={css.margin}>
            <h2 className={css.Title}>
              <FormattedMessage id="ListingPage.accreditationTitle" />
            </h2>

            {industryAccreditationURL ? (
              <a
                href={industryAccreditationURL}
                target="_blank"
                className={css.industryAccreditationURL}
              >
                <FormattedMessage id="ListingPage.accreditationText" />
              </a>
            ) : null}
            <br></br>

            {industryAccreditationURLTwo ? (
              <a
                href={industryAccreditationURLTwo}
                target="_blank"
                className={css.industryAccreditationURL}
              >
                <FormattedMessage id="ListingPage.accreditationText" /> 2
              </a>
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default SectionDetailsMaybe;
