import React from 'react';
import css from './ListingPage.module.css';

function SectionWorktrippLoves(props) {
  const { currentListing } = props;
  const { publicData = [] } = currentListing.attributes;
  const { serviceType = '', worktrippLoves = '', bestFor = '' } = publicData;

  if (worktrippLoves || bestFor) {
    return (
      <div className={css.mobilePadding}>
        <div className={css.worktrippLoves}>
          {worktrippLoves && (
            <div>
              <div className={css.worktrippLovesHeader}>WorkTripp Loves...</div>
              <span className={css.worktrippLovesText}>{worktrippLoves}</span>
            </div>
          )}

          {serviceType == 'VE' && <div className={css.worktrippBestForHeader}>Best for</div>}
          <span className={css.worktrippBestForText}>{bestFor}</span>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
}

export default SectionWorktrippLoves;
