import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { richText } from '../../util/richText';

import css from './ListingPage.module.css';

const SectionClientTestimonials = props => {
  const { tesimonials, images } = props;

  return (
    <div className={css.sectionDescription}>
      <h2 className={css.descriptionTitle}>
        <FormattedMessage id="ListingPage.customerTestimonialsTitle" />
      </h2>
      <p className={css.description}>
        {tesimonials?.map(ts => {
          const { clientName, imageId, testimonialText } = ts;
          // const theImage = images.find(img => img.id.uuid == imageId);
          // const imageURL = theImage && theImage.attributes && theImage.attributes.variants['square-small'] ? theImage.attributes.variants['square-small'].url : '';
          return (
            <div className={css.tesimonials}>
              {/* <div className={css.avatarImageURLDiv}>
                        <img className={css.avatarImageURL} src={imageURL} />
                    </div> */}
              <div className={css.clientNameDiv}>
                <h3 className={css.clientName}>{clientName}</h3>

                <p className={css.testimonialText}>{testimonialText}</p>
              </div>
            </div>
          );
        })}
      </p>
    </div>
  );
};

export default SectionClientTestimonials;
