import React from 'react';
import css from './ListingPage.module.css';
import { thingsToDoList } from '../../util/dataStore';

function SectionThingsToDo(props) {
  const { currentListing } = props;
  const { publicData = [] } = currentListing.attributes;
  const { toDo = [] } = publicData;

  return (
    <div className={css.mobilePadding}>
      {toDo && toDo.length > 0 && (
        <>
          {' '}
          <h2 className={css.thingsToDoTitle}>Things to do</h2>
          <div className={css.thingsToDoContainer}>
            {toDo &&
              toDo.map(e => {
                const { category, description } = e;
                return (
                  <>
                    <div className={css.thingsToDo}>
                      <img src={thingsToDoList.find(t => t.name == category)?.icon} />
                      <div className={css.thingsToDoDesc}>{description}</div>
                    </div>
                  </>
                );
              })}
          </div>{' '}
        </>
      )}
    </div>
  );
}

export default SectionThingsToDo;
