import React from 'react';
import { useState } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { richText } from '../../util/richText';

import css from './ListingPage.module.css';

const MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION = 20;

const SectionDescriptionMaybe = props => {
  const { description } = props;

  const [readMore, setReadMore] = useState(false);

  const toggleButton = () => {
    setReadMore(prevState => !prevState);
  };

  const wholeDescription = readMore ? description : description?.substr(0, 200);
  const shouldShowToggle = description?.length > 200;

  return description ? (
    <div className={css.sectionDescription}>
      <h2 className={css.overviewTitle}>Overview</h2>
      <h2 className={css.descriptionTitle}>
        <FormattedMessage id="ListingPage.descriptionTitle" />
      </h2>
      <p className={css.description}>
        {richText(wholeDescription, {
          longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION,
          longWordClass: css.longWord,
        })}
        {shouldShowToggle ? (
          <a onClick={toggleButton}>
            {wholeDescription?.substr(200) ? ' Read Less' : '...Read More'}
          </a>
        ) : null}
      </p>
    </div>
  ) : null;
};

export default SectionDescriptionMaybe;
