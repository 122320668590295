import React from 'react';
import VectorIcon from '../../assets/icons/Vector.png';
import BuildingIcon from '../../assets/icons/building.png';
import BedIcon from '../../assets/icons/Bed.png';
import EventIcon from '../../assets/icons/Event.png';
import GroupIcon from '../../assets/icons/group.png';
import PeopleIcon from '../../assets/icons/people.png';
import MedalIcon from '../../assets/icons/medal.png';
import CameraIcon from '../../assets/icons/Camera.png';
import css from './ListingPage.module.css';

function ListingPagePlus(props) {
  const {
    currentListing,
    mapRef,
    ratesRef,
    overviewRef,
    videoRef,
    renderListItems,
    facilitiesRef,
    accreditationRef,
    testimonialsRef,
  } = props;

  const { publicData = [] } = currentListing.attributes;

  const {
    listingSector = [],
    listingType: listingTypeId,
    serviceType = '',
    specialism = [],
    venueType = [],
    venueVibe = [],
    modeOfEngagement = [],
    maxEventCapacity = '',
    noOfUniqueBedrooms = '',
    facilities,
    seasonality,
    introductoryVideo,
  } = publicData;

  return (
    <div className={css.mobilePadding}>
      <div className={css.mainWrapper}>
        {venueVibe.length > 0 || specialism.length > 0 ? (
          <div className={css.venueVibe}>
            <img src={VectorIcon} className={css.imgMargin} />
            {venueVibe.length > 0 ? renderListItems(venueVibe, 'venueVibe') : null}
            {specialism.length > 0 ? renderListItems(specialism, 'goals') : null}
          </div>
        ) : null}

        {listingTypeId == 'experiences' ? (
          <div className={css.venueVibe}>
            <img src={GroupIcon} className={css.imgMargin} />
            <span>{maxEventCapacity} Max Group Size</span>
          </div>
        ) : null}

        {venueType.length > 0 && listingTypeId == 'venue' ? (
          <div className={css.specialism}>
            <img src={BuildingIcon} className={css.imgMargin} />
            {renderListItems(venueType, 'venueType')}
          </div>
        ) : (
          <div className={css.venueVibe}>
            <img src={VectorIcon} className={css.imgMargin} />
            {renderListItems(venueType, 'venueType')}
          </div>
        )}

        {noOfUniqueBedrooms && listingTypeId !== 'experiences' ? (
          <div className={css.specialism}>
            <img src={BedIcon} className={css.imgMargin} />
            <span>{noOfUniqueBedrooms} Unique bedrooms</span>
          </div>
        ) : null}

        {maxEventCapacity && listingTypeId !== 'experiences' ? (
          <div className={css.specialism}>
            <img src={EventIcon} className={css.imgMargin} />
            <span>{maxEventCapacity} Event capacity</span>
          </div>
        ) : null}

        {serviceType == 'CFS' && (
          <>
            <div className={css.imgWidth}>
              <img src={MedalIcon} />
              <span>{renderListItems(listingSector, 'listingSector')}</span>
            </div>
            <br></br>
            <div>
              <img src={PeopleIcon} />
              <span>{renderListItems(modeOfEngagement, 'modeOfEngagement')}</span>
            </div>
          </>
        )}
      </div>

      {serviceType == 'CFS' && (
        <div className={css.buttonFlex}>
          <button
            className={css.item}
            onClick={() => overviewRef.current.scrollIntoView({ behavior: 'smooth' })}
          >
            Overview
          </button>
          {facilities && <button className={css.item}>Specialism</button>}
          <button
            onClick={() => accreditationRef.current.scrollIntoView({ behavior: 'smooth' })}
            className={css.item}
          >
            Accreditations
          </button>
          {seasonality && (
            <button
              className={css.item}
              onClick={() => testimonialsRef.current.scrollIntoView({ behavior: 'smooth' })}
            >
              Testimonials
            </button>
          )}
          {introductoryVideo && (
            <button
              className={css.item}
              onClick={() => videoRef.current.scrollIntoView({ behavior: 'smooth' })}
            >
              <img src={CameraIcon} />
            </button>
          )}
        </div>
      )}

      {serviceType == 'VE' && (
        <div className={css.buttonFlex}>
          <button
            className={css.item}
            onClick={() => overviewRef.current.scrollIntoView({ behavior: 'smooth' })}
          >
            Overview
          </button>
          {facilities && (
            <button
              className={css.item}
              onClick={() => facilitiesRef.current.scrollIntoView({ behavior: 'smooth' })}
            >
              Facilities
            </button>
          )}
          <button
            onClick={() => mapRef.current.scrollIntoView({ behavior: 'smooth' })}
            className={css.item}
          >
            Location
          </button>
          {seasonality && (
            <button
              className={css.item}
              onClick={() => ratesRef.current.scrollIntoView({ behavior: 'smooth' })}
            >
              Rates
            </button>
          )}
          {introductoryVideo && (
            <button
              className={css.item}
              onClick={() => videoRef.current.scrollIntoView({ behavior: 'smooth' })}
            >
              <img src={CameraIcon} />
            </button>
          )}
        </div>
      )}
      <span className={css.seperator}></span>
    </div>
  );
}

export default ListingPagePlus;
