import React from 'react';
import css from './ListingPage.module.css';
import DinnerIcon from '../../assets/icons/dinner.png';
import MeetingIcon from '../../assets/icons/meeting.png';
import WorkShopIcon from '../../assets/icons/tools.png';
import SunIcon from '../../assets/icons/sun.png';
import AccessebilityIcon from '../../assets/icons/accessebility.png';

export default function SectionFacilities(props) {
  const { facilities, facilitiesOptions } = props;
  const findValue = key => {
    if (!facilities) return '—';
    const result = facilities.find(fc => {
      return fc.key == key;
    });
    if (result) {
      if (result.value == true) {
        return '✔';
      } else {
        return result.value;
      }
    } else {
      return '—';
    }
  };

  const groupedFacilities = () => {
    if (facilitiesOptions && facilitiesOptions.length > 0) {
      const result = {};

      for (let i = 0; i < facilitiesOptions.length; i++) {
        const { parent, key, label } = facilitiesOptions[i];
        if (!result[parent]) {
          result[parent] = [];
        }
        result[parent].push({ key, label, parent });
      }

      return result;
    } else {
      return [];
    }
  };

  const groupedFacilitiesByParent = groupedFacilities();

  const getLabelFromParent = key => {
    return groupedFacilitiesByParent['Filter Label'].find(s => {
      return s.key == key;
    })?.label;
  };

  const keysIcons = {
    cateringOptions: DinnerIcon,
    meetingSpace: MeetingIcon,
    workshopTools: WorkShopIcon,
    outdoor: SunIcon,
    accessibility: AccessebilityIcon,
  };

  return (
    <div className={css.mobilePadding}>
      <div className={css.mainFacilitiesWrapper}>
        {facilities && facilities.length > 0 && (
          <>
            <h2 className={css.facilitiesHeader}>Facilities</h2>

            <div className={css.facilitiesWrapperAll}>
              <div>
                {Object.keys(groupedFacilitiesByParent).map((fgKey, index) => {
                  if (fgKey !== 'Filter Label' && index < 4) {
                    return (
                      <div>
                        <div className={css.facilitiesFlex}>
                          <span>
                            <img src={keysIcons[fgKey]} />
                          </span>
                          <strong className={css.facilitiesStrong}>
                            {getLabelFromParent(fgKey)}
                          </strong>
                        </div>
                        {groupedFacilitiesByParent[fgKey].map(item => {
                          const { key, label } = item;
                          const correctValue = findValue(key);
                          if (correctValue !== '—') {
                            return (
                              <div className={css.facilitiesWrapper}>
                                <div className={css.facilitiesLabel}>{label}:</div>
                                <div className={css.facilitiesKey}> {correctValue}</div>
                              </div>
                            );
                          }
                        })}
                      </div>
                    );
                  }
                })}
              </div>
              <div>
                {Object.keys(groupedFacilitiesByParent).map((fgKey, index) => {
                  if (fgKey !== 'Filter Label' && index > 3) {
                    return (
                      <div>
                        <div className={css.facilitiesFlex}>
                          <span>
                            <img src={keysIcons[fgKey]} />
                          </span>
                          <strong className={css.facilitiesStrong}>
                            {getLabelFromParent(fgKey)}
                          </strong>
                        </div>
                        {groupedFacilitiesByParent[fgKey].map(item => {
                          const { key, label } = item;
                          const correctValue = findValue(key);
                          if (correctValue !== '—') {
                            return (
                              <div className={css.facilitiesWrapper}>
                                <div className={css.facilitiesLabel}>{label}:</div>
                                <div className={css.facilitiesKey}> {correctValue}</div>
                              </div>
                            );
                          }
                        })}
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
