import React, { useState } from 'react';
import css from './ListingPage.module.css';
import Up from '../../assets/icons/up.png';
import Down from '../../assets/icons/down.png';

function SectionDistance(props) {
  const { currentListing } = props;

  const [open, setOPen] = useState(false);

  const { publicData = [] } = currentListing.attributes;

  const { distances = [] } = publicData;

  const airport = distances.filter(s => s.parent == 'Airport');
  const majorCity = distances.filter(s => s.parent == 'Major city');
  const trainStation = distances.filter(s => s.parent == 'Train station');

  return (
    <div>
      {distances && distances.length > 0 ? (
        <>
          {airport.length > 0 && (
            <div className={css.highSeason}>
              <div
                onClick={() => setOPen(open == 'airport' ? '' : 'airport')}
                className={css.dropDownandUp}
              >
                <p className={css.highSeasonParent}>Planes</p>
                {open && open == 'airport' ? <img src={Up} /> : <img src={Down} />}
              </div>
              {airport.map(e => {
                const { title, desc } = e;
                if (open && open == 'airport') {
                  return (
                    <div className={open ? css.highSeasonDetailsshown : css.highSeasonDetails}>
                      <div>{title}</div>
                      <div>{desc}</div>
                    </div>
                  );
                }
              })}
            </div>
          )}
          {majorCity.length > 0 && (
            <div className={css.highSeason}>
              <div
                onClick={() => setOPen(open == 'majorCity' ? '' : 'majorCity')}
                className={css.dropDownandUp}
              >
                <p className={css.highSeasonParent}>Automobiles</p>
                {open && open == 'majorCity' ? <img src={Up} /> : <img src={Down} />}
              </div>
              {majorCity.map(e => {
                const { title, desc } = e;
                if (open && open == 'majorCity') {
                  return (
                    <div className={open ? css.highSeasonDetailsshown : css.highSeasonDetails}>
                      <div>{title}</div>
                      <div>{desc}</div>
                    </div>
                  );
                }
              })}
            </div>
          )}
          {trainStation.length > 0 && (
            <div className={css.highSeason}>
              <div
                onClick={() => setOPen(open == 'trainStation' ? '' : 'trainStation')}
                className={css.dropDownandUp}
              >
                <p className={css.highSeasonParent}>Trains</p>
                {open && open == 'trainStation' ? <img src={Up} /> : <img src={Down} />}
              </div>
              {trainStation.map(e => {
                const { title, desc } = e;
                if (open && open == 'trainStation') {
                  return (
                    <div className={open ? css.highSeasonDetailsshown : css.highSeasonDetails}>
                      <div>{title}</div>
                      <div>{desc}</div>
                    </div>
                  );
                }
              })}
            </div>
          )}
        </>
      ) : null}
    </div>
  );
}

export default SectionDistance;
